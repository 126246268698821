<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">待确认</el-radio-button>
      <el-radio-button :label="3">已确认</el-radio-button>
      <el-radio-button :label="4">已取消</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDomSel" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="配送日期" prop="distr">
          <el-date-picker
            v-model="table.params.distr"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="currpickerOptions"
            @change="onSearch"
            popper-class="picker-popper">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="下单日期" prop="orderDate">
          <el-date-picker
            v-model="table.params.orderDate"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="标准名称" prop="goods_name">
          <el-input
            size="mini"
            v-model.trim="table.params.goods_name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="供应商/采购员" prop="supplier_id">
          <el-select v-model="table.params.supplier_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getSupplierSel" @change="onSearch">
            <el-option label="空" :value="-1"></el-option>
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" clearable @focus="getMerchantSel" @change="changeMerchant">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 查询有仓库的地区作为选项 -->
        <el-form-item label="食堂地区" prop="address_city">
          <el-select v-model="table.params.address_city" multiple filterable collapse-tags placeholder="请输入" clearable @change="onSearch">
            <el-option
              v-for="item in stashArr"
              :key="item.city"
              :label="item.areaName"
              :value="item.city">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd" style="display:flex;align-items: center;">
        <el-button :size="subUnitSize" type="primary" plain @click="exportData('/admin/purchase_order/exportGoods')" icon="el-icon-download" >导出</el-button>
        <template v-if="table.params.status == 1">
          <el-tooltip content="选择商品后，点击批量审核，系统将自动会根据商品所属供应商分别生成一个采购订单" placement="top-start">
            <el-button :size="subUnitSize" type="primary" plain @click="handlePurchaseBatch" >批量审核<i class="el-icon-warning-outline" style="margin-left:5px;"></i></el-button>
          </el-tooltip>
          <el-tooltip content="无需选择商品，点击一键审核，当前列表所有商品系统将自动根据所属供应商分别生成一个采购订单" placement="top-start">
           <el-button :size="subUnitSize" type="warning" plain @click="handlePurchaseAll" >一键审核<i class="el-icon-warning-outline" style="margin-left:5px;"></i></el-button>
          </el-tooltip>
        </template>
        <el-button :size="subUnitSize" type="primary" plain @click="handlesupplierBatch" v-if="table.params.status != 4">批量修改供应商</el-button>

        <el-checkbox v-model="table.params.is_loss" v-if="table.params.status == 1" @change="onSearch" style="margin-left:20px;">
          计算损耗
          <el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="损耗数量使用商品档案设置的损耗率计算，计算损耗则商品需采购数量=(1+损耗率)*(下单数量-使用库存);若无需采购损耗部分请取消勾选">
             <i class="el-icon-warning" slot="reference" style="color:#e6a23c;"></i>
          </el-popover>
        </el-checkbox>
        <el-checkbox v-model="table.params.is_inventory" v-if="table.params.status == 1" @change="onSearch" style="margin-left:20px;">
          使用库存
          <el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="若您勾选使用库存，将根据订单数量扣减现有库存数量来计算需采购数量。此操作不会减少现有库存，仅帮助您计算仍需采购数量">
             <i class="el-icon-warning" slot="reference" style="color:#e6a23c;"></i>
          </el-popover>
        </el-checkbox>
        
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="(!table.params.is_loss && table.params.status == 1) ? field2 : (table.params.status == 4 ? field4 : field)"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      :updateKey="updateKey"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:spec="{row}">
        <span v-if="row.spec_ids.length > 1">存在多个商品规格</span>
        <span v-else>{{row.spec[0]}}</span>
      </template>
      <template v-slot:cate_name="{row}">
        <span>{{row.cate_name}}</span>
        <span v-if="!!row.order_name_tow"> / {{row.order_name_tow}}</span>
      </template>
      <template v-slot:remark="{row}">
        <el-tooltip placement="top">
          <div slot="content" v-for="(v,i) in row.remark" :key="i" style="margin-bottom:10px;">
            <span>{{v.store_name}}：</span>
            <span v-for="(j,k) in v.remark" :key="k">
              <span>{{j}}</span>
              <span v-if="k < v.remark.length -1">，</span>
            </span>
          </div>
          <div v-if="row.remark.length > 0">
            <div v-for="(v,i) in row.remark" :key="i" class="remarkText">
              <span>{{v.store_name}}：</span>
              <span v-for="(j,k) in v.remark" :key="k">
                <span>{{j}}</span>
                <span v-if="k < v.remark.length -1">，</span>
              </span>
            </div>
          </div>
          <div v-else></div>
        </el-tooltip>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row)">{{table.params.status == 1 ? '下单明细' : '查看详情'}}</el-button>
        <!-- 已确认中：如果该商品原来的供应商已经发货且有数量，则没有修改供应商按钮 （没有字段判断，接口提示） -->
        <el-button type="text" @click="handlesupplier(row)" v-if="table.params.status != 4">修改供应商</el-button>
        <el-button v-if="table.params.status == 1" type="text" @click="handleCancel(row)">取消采购</el-button>
      </template>
    </VTable>

    <!-- 修改供应商 （数据包含供应商/采购员） -->
    <el-dialog
      title="修改供应商"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose1"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="供应商/采购员" prop="supplier_id">
          <el-select v-model="form.data.supplier_id" filterable style="width:100%;margin:10px 0;">
            <el-option v-for="item in supplierOpt" :label="item.name" :value="item.id" :key="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handleRemark">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 审核 -->
    <el-dialog
      title="采购下单"
      :visible.sync="dialogVisibleForm"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom2'
        label-position='right'
        :model='form2.data'
        :rules='form2.rules'
        :size="subUnitSize"
      >
        <div style="margin-bottom:20px;">商品项数：<span style="font-weight:bold;">{{verify_num}}</span>，采购数量：<span style="font-weight:bold;">{{purchase_num}}</span></div>
        <el-form-item label="交货日期" prop="delivery_date">
          <el-date-picker
            v-model="form2.data.delivery_date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div v-if="table.no_bind_count > 0 && is_all == true" style="color:#f56c6c">
        提示：剩余{{table.no_bind_count}}个商品未匹配供应商无法下单，本次仅审核已匹配供应商的商品，确认继续审核吗？
      </div>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form2.loading" :disabled="verify_num == 0 ? true : false" @click="handleVerify">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 明细详情 -->
    <detail ref="detail" :sleDate="table.params" @refresh="getTable"></detail>

  </div>
</template>

<script>
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'

export default {
  name: 'SupplierIndex',
  components: {
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['areaArr']),
  },
  data() {
    const that = this;
    return {
      // badgeDate: ['2025-01-01','2025-01-02','2025-01-03'],
      badgeDate: [],
      currpickerOptions: {
        cellClassName: (time)=> {
          if(that.badgeDate.includes(that.parseTime(time,'{y}-{m}-{d}'))){
            return "badge"
          }
        }
      },
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "good_name", label: "标准名称", width: '120', hidden: false },
        { name: "cate_name", label: "下单分类", width:"120",showTooltip:true, hidden: false},
        { name: "unit_name", label: "单位", width:'90', hidden: false},
        { name: "spec", label: "规格",  hidden: false},
        { name: "order_count", label: "订单笔数",  hidden: false},
        { name: "order_num", label: "订单数量",  hidden: false},
        { name: "use_inventory", label: "使用库存",  hidden: false},
        { name: "loss_num", label: "计算损耗数",  hidden: false},
        { name: "purchase_num", label: "需采购数量", width:"100", hidden: false},
        { name: "remark", label: "备注", width:'150',hidden: false},
        { name: "bind_name", label: "供应商/采购员", width:'150', showTooltip: true, hidden: false},
        { name: "change_time", label: "更新时间", width:'150', hidden: false},
        { name: "action", label: "操作",width: "240", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "good_name", label: "标准名称", width: '120', hidden: false },
        { name: "cate_name", label: "下单分类", width:"120",showTooltip:true, hidden: false},
        { name: "unit_name", label: "单位", width:'90', hidden: false},
        { name: "spec", label: "规格",  hidden: false},
        { name: "order_count", label: "订单笔数",  hidden: false},
        { name: "order_num", label: "订单数量",  hidden: false},
        { name: "use_inventory", label: "使用库存",  hidden: false},
        { name: "purchase_num", label: "需采购数量", width:"100", hidden: false},
        { name: "remark", label: "备注", width:'150',hidden: false},
        { name: "bind_name", label: "供应商/采购员", width:'150', showTooltip: true, hidden: false},
        { name: "change_time", label: "更新时间", width:'150', hidden: false},
        { name: "action", label: "操作",width: "240", fixed:"right", hidden: false }
      ],
      field4: [
        { name: "good_name", label: "标准名称", width: '120', hidden: false },
        { name: "cate_name", label: "下单分类", width:"120",showTooltip:true, hidden: false},
        { name: "unit_name", label: "单位", width:'90', hidden: false},
        { name: "spec", label: "规格",  hidden: false},
        { name: "order_count", label: "订单笔数",  hidden: false},
        { name: "order_num", label: "订单数量",  hidden: false},
        { name: "use_inventory", label: "使用库存",  hidden: false},
        { name: "loss_num", label: "计算损耗数",  hidden: false},
        { name: "remark", label: "备注", width:'150',hidden: false},
        { name: "bind_name", label: "供应商/采购员", width:'150', showTooltip: true, hidden: false},
        { name: "change_time", label: "更新时间", width:'150', hidden: false},
        { name: "action", label: "操作",width: "240", fixed:"right", hidden: false }
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          orderDate:'', //下单日期
          start: '',
          end: '',
          distr: '', // 配送日期
          distr_start: '',
          distr_end: '',
          goods_name: '',
          supplier_id: '',
          address_id: '',
          merchant_id: '',
          order_cate_arr: [],
          order_cate_id: '', // 下单分类
          order_cate_tow: '', // 二级下单分类
          status: 1,  // 1:待确认 2:已确认
          is_loss: true, // 计算损耗 (0:false，1：true)
          is_inventory:false, // 是否使用库存 (0:false，1：true)
          city: '',// 食堂地区
          address_city: [],
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        num1: 0,
        num2: 0,
        goods_count: 0, // 一键审核时总商品数量
        purchase_num: 0, // 一键审核时总采购数量
        no_bind_count: 0, // 一键审核时未绑定供应商的商品数量
        no_bind_num: 0, // 一键审核时未绑定供应商的采购数量
      },
      dialogVisible: false,
      dialogVisibleForm: false,
      form: {
        loading: false,
        data: {
          order_ids: [], 
          supplier_id: '', //供应商
        },
        rules: {
          supplier_id: [{ required: true, message:'请选择供应商', trigger: ['change','blur'] }],
        }
      },
      form2: {
        loading: false,
        data: {
          delivery_date: '',
          // delivery_date: this.$moment(new Date().getTime() + 60 * 60 * 24 * 1000).format("YYYY-MM-DD") // 交货日期默认明天
        },
        rules: {
          delivery_date: [{ required: true, message:'请选择交货日期', trigger: 'blur' }],
        }
      },
      verify_num: 0,
      purchase_num: 0,
      is_all: false, // 是否是一键审核
      checked: false,
      supplierArr: [], // 供应商/采购员
      supplierOpt: [], // 供应商/采购员(过滤掉禁用的供应商)
      merchantArr: [], // 客户列表
      storeArr:[], // 食堂
      orderCateArr: [], // 下单分类
      multipleSelection: [],
      selpickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.isRestart = false
          this.pickerMinDate = minDate.getTime()
          if (maxDate) {
            this.pickerMinDate = ''
          }
        },
        disabledDate: (time) => { //查询时间跨度为7天
          if (this.curveTime && this.isRestart) {
            return // 存在选中的日期且没有重新选择日期 不做禁用处理
          }
          if (this.pickerMinDate !== '') {
            const one = 7 * 24 * 3600 * 1000
            const minTime = this.pickerMinDate - one
            const maxTime = this.pickerMinDate + one
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
      updateKey:new Date()+'',
      stashArr: [],
    }
  },
  created() {
    // 默认当天
    const end = new Date().getTime() + 60 * 60 * 24 * 1000; // 默认明天
    const start = new Date().getTime() + 60 * 60 * 24 * 1000; // 默认明天
    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30); // 往前推一个月
    // 配送日期
    this.table.params.distr = this.$moment(start).format("YYYY-MM-DD");
    this.getDate();// 采购单是否有数据的日期
    this.getStash();
    this.getTable();
  },
  methods: {
    // 切换状态
    handleTabs() {
      this.updateKey = new Date()+'';
        if(this.table.params.status == 3) {
          this.field[this.field.length - 1].width = "180";
        } else if(this.table.params.status == 4) {
          this.field[this.field.length - 1].width = "100";
          // this.field = fieldArr.filter(item => item.name != 'purchase_num')
          // delete this.$options.components['template']
        } else {
          this.field[this.field.length - 1].width = "240";
        }
        this.table.params.page = 1;
        this.table.params.count = 10;
        this.table.data = [];
        this.getTable();
    },
    // 重置查询条件 
    reset() {
      this.$refs.elFormDomSel.resetFields()
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.table.params.order_cate_arr = [];
      this.table.params.order_cate_id = '';
      this.table.params.order_cate_tow = '';
      this.getTable();
    },
    getTable() {
      let _params = {...this.table.params}
      // 下单日期
      if(!!this.table.params.orderDate) {
        _params.start = this.table.params.orderDate;
        _params.end = this.table.params.orderDate;
      }
      // 配送日期
      let _distr = this.table.params.distr;
      if(!!_distr) {
        _params.distr_start = _distr;
        _params.distr_end = _distr;
      }
      _params.is_loss = (this.table.params.is_loss == true && this.table.params.status == 1) ? 1 : 0; //是否勾选计算损耗，不要联动已确认列表
      _params.is_inventory = (this.table.params.is_inventory == true && this.table.params.status == 1) ? 1 : 0; //是否勾选使用库存
     _params.address_city = this.table.params.address_city.length > 0 ? this.table.params.address_city.join(',') : ''
      delete _params.orderDate;
      delete _params.distr;
      delete _params.order_cate_arr;
      this.table.loading = true;
      this.$http.get('/admin/purchase_order/goods', {params:_params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.goods_count = res.data.goods_count; // 总商品数量
          this.table.purchase_num = res.data.purchase_num; //总采购数量
          this.table.no_bind_count = res.data.no_bind_count; // 未绑定供应商的商品数量
          this.table.no_bind_num = res.data.no_bind_num; // 未绑定供应商的采购数量
        }
      })
    },
    // 获取仓库
    getStash() {
      this.$http.get('/admin/stash/list', { params:{ page: 1, count: 10000}}).then(res => {
        if(res.code == 1) {
          this.stashArr = res.data.list.map(item => {
            return {
              ...item,
              areaName: item.province_str+item.city_str
            }
          });
        }
      })
    },
    getDate() {
      const start = new Date() // 默认今天
      const end = new Date().getTime() - 3600 * 1000 * 24 * 365; // 从今天开始往前推1年
      let _start = this.$moment(start).format("YYYY-MM-DD");
      let _end = this.$moment(end).format("YYYY-MM-DD");
      this.$http.get('/admin/purchase_order/goodsData',{params:{distr_start:_end,distr_end:_start}}).then(res => {
        if(res.code == 1) {
          console.log(res.data)
          this.badgeDate = Object.keys(res.data.list)
        }
      })
    },
    // 客户的食堂列表
    getStore() {
      this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
        if(res.code === 1) {
          this.storeArr = res.data.list;
        }
      })
    },
    // 供应商/采购员(status:1启用中的供应商)
    getSupplier() {
      this.$http.get('/admin/supplier/allList',{params:{status:1,page:1, count:10000}}).then(res => {
        if(res.code == 1) {
          this.supplierOpt = res.data.list;
        }
      })
    },
    // 下单明细
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      let obj = {
        ...row,
        is_loss: this.table.params.is_loss, //是否计算损耗
        is_inventory: this.table.params.is_inventory, //是否试用库存
        selStatus: this.table.params.status, //查询状态
      }
      dom.getDetail(obj)
      dom = null
    },
    // 全部导出
    exportData(url) {
      let str = ""
      let _params = {...this.table.params}
      // 下单日期
      if(!!this.table.params.orderDate) {
        _params.start = this.table.params.orderDate;
        _params.end = this.table.params.orderDate;
      }
      // 配送日期
      let _distr = this.table.params.distr;
      if(!!_distr) {
        _params.distr_start = _distr;
        _params.distr_end = _distr;
      }
      _params.is_loss = (this.table.params.is_loss == true && this.table.params.status == 1) ? 1 : 0; // 是否勾选计算损耗，不要联动已确认列表
      _params.is_inventory = (this.table.params.is_inventory == true && this.table.params.status == 1) ? 1 : 0; // 是否勾选使用库存
      delete _params.orderDate;
      delete _params.distr;
      delete _params.order_cate_arr;
      // 没有勾选数据默认是全部导出，勾选了数据就是批量导出
      if(this.multipleSelection.length > 0) {
        let strarr = this.multipleSelection.map(v => {
          return {
            good_name: v.good_name,
            bind_id: v.bind_id,
            unit_name: v.unit_name,
            uni_spec: v.uni_spec,
          }
        })
        // 批量导出需传ids
        _params.ids = JSON.stringify(strarr)
      }
      let obj = {
        ..._params
      }
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    // 取消采购
    handleCancel(row) {
      console.log(row.bind_id, "bind_id")
      this.$confirm('请确认取消采购？', '取消采购', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let _params = {
          start: this.table.params.orderDate, //开始时间
          end: this.table.params.orderDate, //结束时间
          address_id: this.table.params.address_id, //门店ID
          order_cate_id: this.table.params.order_cate_id, //下单分类ID
          order_cate_tow: this.table.params.order_cate_tow, //二级下单分类ID
          supplier_id: row.bind_id != 0 ? row.bind_id : (this.table.params.supplier_id || -1), //供应商ID(列表有供应商先取列表的)
          goods_name: row.good_name, //商品名称
          unit_name: row.unit_name, // 单位
          uni_spec: row.uni_spec, // 区分标识
          is_inventory: this.table.params.is_inventory == true ? 1 : 0, //是否使用库存
          distr_start: !!this.table.params.distr ? this.table.params.distr : '',
          distr_end: !!this.table.params.distr ? this.table.params.distr : '',      

        }
        console.log(_params)
        this.$http.post('/admin/purchase_order/cancelGoods', _params).then(res => {
          if(res.code == 1) {
            this.$message.success("操作成功！")
            this.getTable();
          }
        })
      }).catch(() => {})
    },
    countNum(arr) {
      let sum = 0;
      arr.map(function (item) {
        sum += Number(item.purchase_num)
      })
      return sum.toFixed(2) * 1
    },
    // 一键审核
    async handlePurchaseAll() {
      if(this.table.total == 0) {
        this.$message.warning("无审核数据")
        return
      }
      this.is_all = true; // 是否是一键审核
      this.dialogVisibleForm = true;
      this.form2.data.delivery_date = this.table.params.distr; // 交货日期默认查询条件的配送日期
      this.verify_num = (((Number(this.table.total) * 100) - (Number(this.table.no_bind_count)*100)) / 100).toFixed(2) * 1; // 商品项数 - 未绑定供应商的商品数量
      this.purchase_num = (((Number(this.table.purchase_num) * 100) - (Number(this.table.no_bind_num)*100)) / 100).toFixed(2) * 1 //采购数量 - 未绑定供应商的采购数量
      console.log(this.purchase_num, "采购数量");
   },
    // 批量审核
    handlePurchaseBatch() {
      if(this.multipleSelection.length > 0) {
        this.is_all = false;
        let is_num = this.multipleSelection.some((item) => item.bind_id == 0); // 检测选择的数据是否有未帮供应商的商品
        if(is_num) {
          return this.$message.warning("抱歉，您所选中的商品缺失供应商，请检查并匹配后再审核")
        }
        this.dialogVisibleForm = true;
        this.form2.data.delivery_date = this.table.params.distr; // 交货日期默认查询条件的配送日期
        this.verify_num = this.multipleSelection.length;
        this.purchase_num = this.countNum(this.multipleSelection);
      } else {
        this.$message.warning("请选择需审核的商品")
      }
    },
    // 批量审核方法
    PurchaseBatchFun() {
      let _params = {
        start: !!this.table.params.orderDate ? this.table.params.orderDate : '', //开始时间
        end: !!this.table.params.orderDate ? this.table.params.orderDate : '', //结束时间
        distr_start: !!this.table.params.distr ? this.table.params.distr : '',  // 配送日期
        distr_end: !!this.table.params.distr ? this.table.params.distr : '', // 配送日期
        address_id: this.table.params.address_id, //门店ID
        order_cate_id: this.table.params.order_cate_id, //下单分类ID
        order_cate_tow: this.table.params.order_cate_tow, //二级下单分类ID
        is_loss: this.table.params.is_loss == true ? 1 : 0,
        is_inventory: this.table.params.is_inventory == true ? 1 : 0,
        delivery_date: this.form2.data.delivery_date, // 交货时间
        // （ 如果is_all:true是一键审核则不需要传spec）
        spec: !!this.is_all ? [] : this.multipleSelection.map(v => {
          return {
            norm_name: v.good_name, //标准名称
            bind_id: v.bind_id, //采购员或供应商
            unit_name: v.unit_name, //单位
            uni_spec: v.uni_spec //区分标识
          }
        }),
      }
      this.form2.loading = true;
      this.$http.post('/admin/purchase_order/patchPurchase',_params).then(res => {
        if(res.code == 1) {
          this.$message.success("操作成功！")
          this.getTable();
          this.dialogVisibleForm = false;
          // this.form2.data.delivery_date = this.$moment(new Date().getTime() + 60 * 60 * 24 * 1000).format("YYYY-MM-DD") // 交货日期默认明天
          this.form2.data.delivery_date = '';
        }
      }).finally(() => {
        this.form2.loading = false;
      })
    },
    // 批量修改供应商
    handlesupplierBatch() {
      if(this.multipleSelection.length > 0) {
        this.getSupplier();
        this.dialogVisible = true;
        // 将选择数据列表中order_ids，取二级数组组成一个新数组
        this.form.data.order_ids = this.multipleSelection.reduce((acc, item) => acc.concat(item.order_ids), [])
      } else {
        this.$message.warning("请先选择需要修改供应商的数据")
      }
    },
    // 修改供应商
    handlesupplier(row) {
      this.getSupplier();
      this.dialogVisible = true;
      this.form.data.order_ids = row.order_ids; // 采购单id汇总数组
      this.form.data.supplier_id = row.supplier_id != 0 ? Number(row.supplier_id) : ''; // 供应商/采购员
    },
    // 修改供应商
    handleRemark() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {
            details: this.form.data.order_ids.join("|"), //订单明细Id 多个竖线隔开
            supplier_id: this.form.data.supplier_id, // 供应商/采购员id
            type: this.table.params.status == 1 ? 1 : 2 //类型 1:待确认 2:已确认
          }
          this.form.loading = true;
          this.$http.post('/admin/order/updateBuyer', _params).then(res => {
            if(res.code == 1) {
              this.dialogVisible = false;
              this.$message.success("操作成功！")
              this.getTable(); // 更新列表
              this.multipleSelection = [];
              this.form.data.supplier_id = '';
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    },
    handleVerify() {
      this.$refs.elFormDom2.validate(valid => {
        if(valid) {
          this.PurchaseBatchFun();
        }
      })
    },
    handleClose1() {
      this.dialogVisible = false;
      this.form.data.supplier_id = '';
      this.$refs.elFormDom.resetFields();
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisibleForm = false;
      this.$refs.elFormDom2.resetFields();
      // this.form2.data.delivery_date = this.$moment(new Date().getTime() + 60 * 60 * 24 * 1000).format("YYYY-MM-DD") // 交货日期默认明天
      this.form2.data.delivery_date = '';
    },
    parseTime(time,cFormat) {
      if(arguments.length === 0 || !time){
        return null
      }
      const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
      let date
      if(typeof time === 'object') {
        date = time
      } else {
        if((typeof time === 'string')) {
          if((/^[0-9]+$/.test(time))){
            time = parseInt(time)
          } else {
            time = time.replace(new RegExp(/-/gm),'/')
          }
        }
      }
      if((typeof time === 'number') && (time.toString().length === 10)){
        time = time * 1000
      }
      date = new Date(time)
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth()+1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      }
      const time_str = format.replace(/{([ymdhisa])+}/g,(result, key) => {
        const value = formatObj[key]
        if(key === 'a') { return ['日','一','二','三','四','五','六'][value] }
        return value.toString().padStart(2,'0')
      })
      return time_str
    },
    
  }
}
</script>
<style scoped>
.remarkText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
<style >
.badge::before {
  position: absolute;
  content: "";
  right: 5px;
  bottom: 12px;
  width: 5px;
  height: 5px;
  border-radius:50%;
  background-color:red;
}
</style>
